<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Filters">
          <b-form>
            <b-row>
              <b-col>
                <b-form-group
                  label="Showcase Date range"
                  label-for="date-range"
                >
                  <flat-pickr
                    id="date-range"
                    v-model="filters.dateRange"
                    class="form-control"
                    :config="{ mode: 'range' }"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Compare to Date range"
                  label-for="date-range"
                >
                  <flat-pickr
                    id="date-range"
                    v-model="filters.compareDateRange"
                    class="form-control"
                    :config="{ mode: 'range' }"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="suggestionList.length > 1">
                <b-form-group
                  id="company"
                  label="Company"
                  label-for="company"
                >
                  <v-select
                    v-model="companyIds"
                    :reduce="option => option.value"
                    label="label"
                    multiple
                    :options="suggestionList"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-button
                  type="button"
                  variant="primary"
                  style="margin-top: 20px"
                  @click="fetchData"
                >
                  Filter
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <div class="overflow-x-scroll">
            <b-table
              :items="filteredInvoices"
              responsive
              :fields="fields"
              :busy="loading"
              stacked="sm"
              class="mb-0"
              show-empty
              hover
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading data ...</strong>
                </div>
              </template>

              <template #cell(company_name)="data">
                {{ data.value }}
              </template>

              <template #cell()="data">
                <invoice-value :invoice-val="data.value" />
              </template>
              <template v-slot:bottom-row>
                <b-td>
                  <strong>Total</strong>
                </b-td>
                <b-td
                  v-for="col in totals"
                  :key="col.label"
                >
                  <invoice-value :invoice-val="col" />
                </b-td>
              </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BTable,
  BSpinner,
  BTd, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { getSelectedCompanies, parseFilters, updateSelectedCompanies } from '@core/utils/filter'
import handleError from '@/views/components/errorHandler'
import moment from 'moment'
import InvoiceValue from '@/views/invoicing/InvoiceValue.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BButton,
    InvoiceValue,
    BTd,
    BSpinner,
    BTable,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BCard,
  },
  data() {
    return {
      filters: {
        dateRange: `${moment().startOf('month').format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`,
        compareDateRange: `${moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')}`
          + ` to ${moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}`,
      },
      loading: false,
      companyIds: getSelectedCompanies([this.$activeCompany().data.company.id]),
      companyList: [],
      invoices: [],
      indexUrl: '/v1/invoices',
      fields: [
        { key: 'company_name', label: 'Company', sortable: false },
        { key: 'shipments_count', label: 'Shipments', sortable: false },
        { key: 'parcels_count', label: 'Parcels', sortable: false },
        { key: 'items_count', label: 'Items', sortable: false },
        { key: 'weight', label: 'Weight, kg', sortable: false },
        { key: 'created_declarations_count', label: 'H7 created', sortable: false },
        { key: 'created_declarations_weight', label: 'Weight submitted, kg', sortable: false },
        { key: 'cleared_declarations_count', label: 'H7 cleared', sortable: false },
        { key: 'exported_parcels_count', label: 'Exported', sortable: false },
        { key: 'ens_declarations_parcels', label: 'Ens parcels', sortable: false },
        { key: 'ens_declarations_items', label: 'Ens items', sortable: false },
        { key: 'ens_declarations_weight', label: 'Ens weight', sortable: false },
      ],
    }
  },
  computed: {
    suggestionList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
    filteredInvoices() {
      return this.invoices.filter(item => this.fields.filter(field => !['company_name'].includes(field.key))
        .reduce((sum, field) => sum + (parseFloat(item[field.key].current) + parseFloat(item[field.key].previous)), 0) > 0)
    },
    totals() {
      return this.filteredInvoices.reduce((res, item) => {
        this.fields.filter(field => !['company_name'].includes(field.key)).forEach(field => {
          res[field.key] = res[field.key] || { current: 0, previous: 0 }
          res[field.key].current += parseFloat(item[field.key].current) || 0
          res[field.key].previous += parseFloat(item[field.key].previous) || 0
        })
        return res
      }, {})
    },
  },
  mounted() {
    this.$data.companyList = this.$profile().data.user.companies
    this.fetchData()
  },
  methods: {
    searchQuery(url) {
      const filters = { ...this.$data.filters, companyIds: this.companyIds }
      const filterList = parseFilters(filters)
      const queryString = Object.keys(filterList)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filterList[key])}`)
        .join('&')

      url += `?${queryString}`

      updateSelectedCompanies(this.companyIds)

      return url
    },
    fetchData() {
      this.loading = true
      // Request
      this.$http.get(this.searchQuery(this.indexUrl))
        .then(response => {
          this.$data.invoices = response.data.data
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
