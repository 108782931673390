<template>
  <div>
    <strong
      v-if="invoiceVal.previous > 0 || invoiceVal.current > 0"
    >
      {{ invoiceVal.current | formatNumberWithZero }}
    </strong>
    <sup
      v-if="invoiceVal.previous > 0 || invoiceVal.current > 0"
      class="prevent-select"
      :class="{
        'text-success': invoiceVal.current > invoiceVal.previous,
        'text-danger': invoiceVal.current < invoiceVal.previous,
        'text-muted': invoiceVal.current === invoiceVal.previous
      }"
    >
      <b-icon-arrow-up v-if="invoiceVal.current > invoiceVal.previous" />
      <b-icon-arrow-down v-if="invoiceVal.current < invoiceVal.previous" />
      {{ calculateDifference(invoiceVal) }}%
    </sup>
    <span
      class="text-muted prevent-select"
      :class="invoiceVal.previous > 0 || invoiceVal.current > 0 ? ' ml-1' : ''"
    >
      {{ invoiceVal.previous | formatNumberWithZero }}
    </span>
  </div>
</template>

<script>
import { BIconArrowDown, BIconArrowUp } from 'bootstrap-vue'

export default {
  components: { BIconArrowDown, BIconArrowUp },
  props: {
    invoiceVal: {
      type: Object,
      require: true,
    },
  },
  methods: {
    calculateDifference(value) {
      if (value.current === value.previous) {
        return 0
      }

      return value.previous === 0 ? 100 : Math.round(((value.current - value.previous) / value.previous) * 100)
    },
  },
}
</script>
